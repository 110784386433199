ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #333;
}

li {
    float: left;
}

li a {
    display: block;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
}

.navbar {
    margin-bottom: 2rem;
}

/* Change the link color to #111 (black) on hover */
li a:hover {
    background-color: #111;
}